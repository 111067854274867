:root {
  --main-bg-color: #f9fcff;
  --main-text-color: black;
  --secondary-bg-color: #50b3b8;
  --secondary-bg-color-offset: #172920;
  --tertiary-bg-color: #daf4e1;
  --contrast-color: #93e7e9;
}

.App {
  text-align: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-start;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: var(--main-bg-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: #bb8116;
}

.App-link {
  color: #61dafb;
}

.CellInputError {
  color: red;
}

.CellOk {
}

.Cell {
  width: 19.5rem;
  height: 100%;
}

.RootColumn {
  width: 100%;
  overflow-x: scroll;
}

.Column-div {
  display: flex;
  flex: 0 0 20rem;
  flex-direction: row;
  height: 100%;
}

.variableName {
  display: flex;
  font-size: 0.6em;
  width: 19.5rem;
}

.headerMiddle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 70rem;
}

.thymeLogo {
  display: flex;
  font-size: 2em;
  margin: 0 0.6em 0 0.3em;
}

.headerColor {
  color: white;
}

.headerMiddleTop {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 95%;
}

.headerMiddleBottom {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.isDefaulttrue {
  color: gray;
}

.isDefaultfalse {
  color: black;
}

.AddColumn-button {
  background: var(--contrast-color);
  color: var(--main-bg-color);
}

.Checkbox-div {
  flex: 0 0 2rem
}

.Row-div {
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: row;
  height: 1.9rem;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
}

.contextMenu {
  position: fixed;
  background: var(--tertiary-bg-color);
  box-shadow: 0px 2px 10px #999999;
	color: black;
	z-index: 1001;
}

.contextMenu--option {
		padding: 6px 50px 5px 10px;
		min-width: 160px;
		cursor: default;
		font-size: 12px;
}
.contextMenu--option:hover {
		background: linear-gradient(to top, #555, #333);
		color: white;
}

.modelListItem {
		padding: 6px 50px 5px 10px;
		min-width: 160px;
		cursor: default;
		font-size: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.modelListItem:hover {
		background: linear-gradient(to top, #555, #333);
    color: white;
}
.newModel {
}
.modelSelector {
		z-index: 1001;
}
.modelSelector .header {
		background: var(--secondary-bg-color);
    color: var(--secondary-bg-color-offset);
    font-size: 1.4em;
}

.login {
  position: fixed;
  background: var(--tertiary-bg-color);
  box-shadow: 0px 2px 10px #999999;
	color: black;
  top: 20%;
  left: 50%;
  width: 500px;
  margin-left: -250px;
  z-index: 1001;
}

.loginHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: var(--secondary-bg-color);
  color: white;
  padding: 0.2em 0.2em 0em 0.2em;
}
.loginTab {
  width: 50%;
  padding: 0 0 0.2em 0;
}
.loginHeader .selected {
  background-color: var(--tertiary-bg-color);
  color: black;
}

.loginForm {
  display: flex;
  flex-direction: column;
  padding: 0.2em;
}
.loginForm input {
  margin: 0.5em;
}
.loginForm .errors {
  color: red;
}

.modelSelector {
  position: fixed;
  background: var(--tertiary-bg-color);
  box-shadow: 0px 2px 10px #999999;
	color: black;
  top: 20%;
  left: 50%;
  width: 500px;
  margin-left: -250px;
  border-radius: 8px;
}

.headerBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background: var(--secondary-bg-color);
  position: sticky;
  top: 0;
}

.app {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.logout {
  align-items: flex-end;
  font-size: .5em;
}

.modelTitle {
  font-size: 1em;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.headerBar button {
  border: 2px;
  background-color: var(--secondary-bg-color);
  font-family: inherit;
  padding: 0;
  cursor: pointer;

  color: var(--secondary-bg-color-offset);
  border-radius: 8px;

  padding: 0.25em 0.75em 0.25em 0.75em;
  min-width: 10ch;
  min-height: 0.5em;

  @media screen and (-ms-high-contrast: active) {
    border: 2px solid currentcolor;
  }
}
.headerBar button:hover{
  background-color: var(--contrast-color);
}

.bodyButton {
  border: 0px;
  background-color: var(--secondary-bg-color);
  font-family: inherit;
  cursor: pointer;

  color: var(--secondary-bg-color-offset);
  border-radius: 8px;

  margin: 0.2em;
  padding: 0.25em 0.75em 0.25em 0.75em;
  min-width: 10ch;
  min-height: 0.5em;
  width: 100%;

  @media screen and (-ms-high-contrast: active) {
    border: 2px solid currentcolor;
  }
  text-align: center;
}
.bodyButton:hover {
  background-color: var(--contrast-color);
}
.bodyButton:disabled {
  background-color: grey;
}
.loginForm .bodyButton {
  margin: 1em;
  width: 95%;
}
.logoutButton {
  font-size: 1em;
}

.gridOutline {
  border: 2px solid orange;
  border-radius: 10px;
  margin-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.notSaved {
  color: var(--tertiary-bg-color);
  font-size: 1em;
}

.modelSelectorTab {
  margin: 0 0.5em 0 0.5em;
}

.modelSelectorTabs {
  flex-direction: row;
  display: flex;
  justify-content: space-evenly;
  background-color: var(--secondary-bg-color);
  color: var(--secondary-bg-color-offset);
  font-size: 1.0em;
}

.modelSelectorTabs .unselected {
}

.modelSelectorTabs .selected {
  background-color: var(--tertiary-bg-color);
}

.overlay {
		opacity: 0.0;
    background-color: #ccc;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 1000;
}

.termsOfUseText {
    position: fixed;
    z-index: 1001;
    top: 10%;
    left: 50%;
    width: 1000px;
    height: 80%;
    margin-left: -500px;
    background: var(--tertiary-bg-color);
    box-shadow: 0px 2px 10px #999999;
    color: black;
    overflow-y: auto;
    text-align: left;
    font-size: 14px;
}

.deleteModelBox {
    position: fixed;
    z-index: 1001;
    top: 20%;
    left: 50%;
    width: 500px;
    margin-left: -250px;
    background: var(--tertiary-bg-color);
    box-shadow: 0px 2px 10px #999999;
    color: black;
}

.deleteModelRow {
  display: flex;
  justify-content: space-evenly;
  margin: 5px;
}

.variableEditorBox {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 0.6em;
  color: black;
  background: var(--tertiary-bg-color);
  position: fixed;
}

.variableEditorRow {
  display: flex;
  flex-direction: row;
  padding: 0.5em;
}

.gapBehindVariableEditor {
  height: 1.5em;
}

.variableEditorInput {
  flex-grow: 1;
}

.variableEditorErrors {
  color: red;
}

.headerBar a {
  text-decoration: none;
}

.variableName {
  flex-shrink: 1;
  font-size: 14px;
}

.prefabModal {
  position: fixed;
  background: var(--tertiary-bg-color);
  box-shadow: 0px 2px 10px #999999;
	color: black;
  top: 20%;
  left: 50%;
  width: 500px;
  margin-left: -250px;
  z-index: 1001;
  font-size: 14px;
}

.prefabModalTitle {
  font-size: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: var(--secondary-bg-color);
  color: white;
  padding: 0.2em 0.2em 0em 0.2em;
}

.prefabModalTextInput {
  display: flex;
  justify-content: flex-start;
}

.prefabModalTextInput input {
  width: 100%;
}

.prefabModal table {
  margin-left: auto;
  margin-right: auto;
}

.prefab {
  flex-direction: column;
  align-content: center;
  align-items: center;
  flex-direction: row;
  margin: 0.5em;
  padding: 1em;
  background-color: var(--tertiary-bg-color);
}

.prefabName {
  display: flex;
}

.prefabBinding {
  display: flex;
  font-size: 14px;
  color: black;
}

.prefabInputColumns {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  font-size: 20px;
}

.prefabInputColumn {
  display: flex;
  flex-direction: column;
}
